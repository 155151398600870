import React, { useState } from "react";

function Login({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");

  const handlePasswordSubmit = () => {
    // Proceed to the 2FA step only if fields are not empty.
    if (username.trim() === "" || password.trim() === "") {
      setError("Please enter both username and password");
      return;
    }
    setError("");
    setStep(2);
  };

  const handle2FASubmit = async () => {
    if (authCode.trim() === "") {
      setError("Please enter your authentication code");
      return;
    }
    setError("");

    try {
      const response = await fetch(
        "https://secure-chat-backend-production-1f37.up.railway.app/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            username,
            password,
            token: authCode,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        onLogin(username);
      } else {
        setError(data.error || "Login failed");
      }
    } catch (err) {
      console.error("Error during login:", err);
      setError("Network error");
    }
  };

  return (
    <div>
      {step === 1 ? (
        <div>
          <h2>Login</h2>
          <input
            type="text"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handlePasswordSubmit}>Next</button>
        </div>
      ) : (
        <div>
          <h2>Enter 2FA Code</h2>
          <input
            type="text"
            placeholder="Authentication Code"
            onChange={(e) => setAuthCode(e.target.value)}
          />
          <button onClick={handle2FASubmit}>Login</button>
        </div>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}

export default Login;