import React, { useState } from "react";
import Login from "./Login";
import Chat from "./Chat";

console.log("Using new App.js code");

function App() {
  const [user, setUser] = useState(null);

  return (
    <div>
      {!user ? (
        <Login onLogin={(username) => setUser(username)} />
      ) : (
        <Chat username={user} />
      )}
    </div>
  );
}

export default App;
