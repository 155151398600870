import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import CryptoJS from "crypto-js";
import EmojiPicker from "emoji-picker-react";

// Optionally attach CryptoJS to the global window (for debugging)
window.CryptoJS = CryptoJS;

// Update the URL to your deployed backend
const socket = io("https://secure-chat-backend-production-1f37.up.railway.app");

// Helper function to format timestamps
function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Simple today/yesterday logic:
  const isToday = date.toDateString() === now.toDateString();
  now.setDate(now.getDate() - 1);
  const isYesterday = date.toDateString() === now.toDateString();

  if (isToday) return `today at ${hours}:${minutes}`;
  if (isYesterday) return `yesterday at ${hours}:${minutes}`;
  return date.toLocaleDateString() + " " + hours + ":" + minutes;
}

function Chat({ username }) {
  // For demo: if user1 logs in, show user2 on top
  const otherUser = username === "user1" ? "user2" : "user1";
  const [lastSeen] = useState("today at 20:03"); // example last seen

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  // We're not using the emoji picker now, so we remove its state
  const messageListRef = useRef(null);

  // Layout styles
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100vh",
    backgroundColor: "#f0f0f0",
    overflow: "hidden",
  };

  // Fixed top bar
  const topBarStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "60px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#075E54",
    color: "#fff",
    padding: "10px",
    paddingTop: "env(safe-area-inset-top)", // ensure safe area is respected
    zIndex: 1000,
  };
  

  const avatarStyle = {
    fontSize: "1.5rem",
    marginRight: "10px",
  };

  const nameStyle = {
    fontWeight: "bold",
    fontSize: "1rem",
  };

  const lastSeenStyle = {
    fontSize: "0.8rem",
    opacity: 0.8,
  };

  // Scrollable message area
  const messageListStyle = {
    position: "absolute",
    top: "60px",      // below top bar
    bottom: "50px",   // above bottom bar
    left: 0,
    right: 0,
    overflowY: "auto",
    padding: "10px",
    paddingBottom: "80px", // extra space so last message isn't hidden
  };

  // Message bubble style
  const bubbleStyle = (isUser) => ({
    display: "inline-block",
    backgroundColor: isUser ? "#0084ff" : "#e5e5ea",
    color: isUser ? "white" : "black",
    padding: "5px 8px",
    borderRadius: "7px",
    margin: "2px 0", // reduced margin for tighter spacing
    alignSelf: isUser ? "flex-end" : "flex-start",
    maxWidth: "80%",
  });
  


  // Fixed bottom input bar
  const inputBarStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "50px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000",
    borderTop: "1px solid #ccc",
    padding: "5px 10px",
    zIndex: 999,
  };

  const inputStyle = {
    flex: 1,
    backgroundColor: "#fff",
    padding: "8px",
    marginRight: "8px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "0.9rem",
  };

  // Auto-scroll to the bottom when messages update
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  // Fetch old messages and set up socket listener on mount
  useEffect(() => {
    fetch("https://secure-chat-backend-production-1f37.up.railway.app/messages")
      .then((res) => res.json())
      .then((oldMessages) => {
        console.log("Fetched old messages:", oldMessages);
        const decrypted = oldMessages.map((m) => ({
          username: m.username,
          text: CryptoJS.AES.decrypt(m.encryptedMessage, "SECRET_KEY").toString(CryptoJS.enc.Utf8),
          timestamp: m.timestamp,
        }));
        setMessages(decrypted);
      })
      .catch((err) => console.error("Failed to fetch messages:", err));

    const listener = ({ username: sender, encryptedMessage, timestamp }) => {
      const decrypted = CryptoJS.AES.decrypt(encryptedMessage, "SECRET_KEY").toString(CryptoJS.enc.Utf8);
      setMessages((prev) => [...prev, { username: sender, text: decrypted, timestamp }]);
    };
    socket.on("receiveMessage", listener);
    return () => socket.off("receiveMessage", listener);
  }, []);

  const sendMessage = () => {
    if (!message.trim()) return;
    const encrypted = CryptoJS.AES.encrypt(message, "SECRET_KEY").toString();
    socket.emit("sendMessage", { username, encryptedMessage: encrypted });
    setMessage("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  return (
    <div style={containerStyle}>
      {/* Fixed Top Bar */}
      <div style={topBarStyle}>
        <span style={avatarStyle}>👤</span>
        <div>
          <div style={nameStyle}>{otherUser}</div>
          <div style={lastSeenStyle}>Last seen {lastSeen}</div>
        </div>
      </div>

      {/* Scrollable Message Area */}
      <div style={messageListStyle} ref={messageListRef}>
        {messages.map((msg, index) => {
          const isUser = msg.username === username;
          // Determine if this message is the last in a consecutive group
          let showTimestamp = false;
          if (index === messages.length - 1) {
            showTimestamp = true;
          } else if (messages[index].username !== messages[index + 1].username) {
            showTimestamp = true;
          }
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: isUser ? "flex-end" : "flex-start",
                marginBottom: "8px",
              }}
            >
              <div style={bubbleStyle(isUser)}>
                {msg.text}
              </div>
              {showTimestamp && (
                <div style={{ fontSize: "0.7rem", marginTop: "2px", color: "#666" }}>
                  {formatTimestamp(msg.timestamp)}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Fixed Bottom Input Bar */}
      <div style={inputBarStyle}>
        <input
          type="text"
          style={inputStyle}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
        />
        <button
          onClick={sendMessage}
          style={{
            color: "#fff",
            background: "none",
            border: "none",
            fontSize: "1.2rem",
          }}
        >
          ↑
        </button>
      </div>
    </div>
  );
}

export default Chat;