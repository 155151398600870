// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css"; // if you have an index.css for styling
import App from "./App"; // Must match "App.js" in the same src folder

// Create the root and render
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);